/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:20:54 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 18:21:47
 */
<template>
  <div class="question_details">
    <Banner></Banner>
    <div class="cont width_1200">
      <div class="title">{{ question.Name }}</div>
      <!-- <ul class="practice">
        <li
          v-for="(par, i) in practices"
          :key="i"
          :class="practicesIndex == par.id ? 'active' : ''"
          @click="practicesClick(par)"
        >
          <i :class="par.icon"></i>
          <p>{{ par.name }}</p>
        </li>
      </ul> -->
      <div
        class="rollUp"
        v-if="
          practicesIndex == 1 ||
          practicesIndex == 3 ||
          practicesIndex == 4 ||
          practicesIndex == 5
        "
      >
        <div
          class="rollUp_top"
          v-if="
            practicesIndex != 5 && practicesIndex != 3 && practicesIndex != 4
          "
        >
          <span
            v-for="(rl, i) in contacts"
            :key="i"
            :class="contactsIndex == i ? 'active' : ''"
            @click="contactsClick(rl, i)"
            >{{ rl }}</span
          >
        </div>
        <div class="rollUp_cont" v-if="practicesIndex == 1">
          <div v-if="list.length > 0">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClick(li)"
            >
              <el-col :span="20">{{ li.Name }}</el-col>
              <el-col :span="4" class="text_align_right">
                <p class="c_1288F4" v-if="li.Category == 2">免费</p>
                <p
                  class="c_F40707"
                  v-else-if="li.IsSale != 1 && li.Category == 0"
                >
                  课程专属
                </p>
                <p
                  class="c_F40707"
                  v-else-if="li.IsSale != 1 && li.Category == 1"
                >
                  VIP专属
                </p>
                <p class="c_F40707" v-else-if="li.IsSale == 1">
                  ￥{{ li.Price | priceToFixed }}
                </p>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          class="rollUp_cont"
          v-if="practicesIndex == 3 || practicesIndex == 4"
        >
          <el-tree
            :indent="25"
            default-expand-all
            highlight-current
            :expand-on-click-node="false"
            :data="list"
            :props="defaultProps"
            @node-click="handleNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.li_attr.questionCount > 0" class="tree_right">
                <el-progress
                  :percentage="
                    (data.li_attr.AnswerCount / data.li_attr.questionCount) *
                    100
                  "
                  :color="customColor"
                ></el-progress>
                <div class="proportion">
                  {{ data.li_attr.AnswerCount }}/{{
                    data.li_attr.questionCount
                  }}
                </div>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="rollUp_cont" v-if="practicesIndex == 5">
          <el-row type="flex" justify="center">
            <el-col :span="12">题型</el-col>
            <el-col :span="8">题目数</el-col>
            <el-col :span="4" class="text_align_right"></el-col>
          </el-row>
          <div v-if="list.length > 0">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClick(li)"
            >
              <el-col :span="12">{{ li.Name }}</el-col>
              <el-col :span="8">{{ li.QuestionCount }}</el-col>
              <el-col :span="4" class="text_align_right">
                <p class="c_1288F4" v-if="li.Category == 2">免费</p>
                <p
                  class="c_F40707"
                  v-else-if="li.IsSale != 1 && li.Category == 0"
                >
                  课程专属
                </p>
                <p
                  class="c_F40707"
                  v-else-if="li.IsSale != 1 && li.Category == 1"
                >
                  VIP专属
                </p>
                <p class="c_F40707" v-else-if="li.IsSale == 1">
                  ￥{{ li.Price | priceToFixed }}
                </p>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import Banner from "./components/banner";
import camera from "@/components/canvasCamera.vue";
import {
  questionBankQueryPaperList,
  questionBankQueryChapters,
} from "@/api/questionBank";
import { studentCompareFace } from "@/api/api";
import noData from "@/components/noData";

export default {
  data () {
    return {
      question: {},
      practices: [
        { id: 1, name: "套卷练习", icon: "el-icon-tickets" },
        // { id: 2, name: "随机练习", icon: "el-icon-document" },
        { id: 3, name: "章节练习", icon: "el-icon-notebook-2" },
        { id: 4, name: "名词刷题", icon: "el-icon-document-checked" },
        { id: 5, name: "题型练习", icon: "el-icon-reading" },
      ],
      practicesIndex: 1,
      list: [],
      contacts: [],
      contactsIndex: 0,
      rollUps: ["全部", "模拟考试", "历年真题", "专项练习"],
      chapters: ["章节"],
      paperType: "",
      page: 1, //页码
      pageSize: 10, //每页条数
      total: 0,
      defaultProps: {
        label: "text",
        children: "children",
      },
      customColor: "#1288f4",
      dialogVisible: false,
      jump: {},
      user: {},
    };
  },
  components: {
    Banner,
    camera, noData
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.init();
    this.contacts = this.rollUps;
  },
  mounted () { },
  methods: {
    getClose () {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace (flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
      } else {
        // this.$message.error(res.msg);
        this.$message.error("识别失败");
        this.dialogVisible = false;
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
    },
    handleNodeClick (obj) {
      let ob = {}
      if (obj.li_attr.questionCount > 0) {
        if (this.practicesIndex == 3) {
          ob = {
            Id: obj.id,
            CourseId: this.question.Id,
            Name: obj.text,
            UserID: this.user.Id,
          };
          this.jump.url = "/onlineQuestionBank/chapterExercises";
        } else if (this.practicesIndex == 4) {
          ob = {
            CourseId: this.question.Id,
            ChapterId: obj.id,
            Name: obj.text,
            UserID: this.user.Id,
          };
          this.jump.url = "/onlineQuestionBank/recitationMode";
        }
        this.jump.ob = ob;
        // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        this.dialogVisible = true;
      }
    },
    parcticeClick (obj) {
      let ob = {
        Id: obj.Id,
      };
      this.$router.push({
        path: "/onlineQuestionBank/practiceDetails",
        query: ob,
      });
    },
    //考题类型
    contactsClick (obj, i) {
      this.contactsIndex = i;
      if (this.contactsIndex == 0) {
        this.paperType = "";
      } else if (this.contactsIndex == 1) {
        this.paperType = 0;
      } else if (this.contactsIndex == 2) {
        this.paperType = 1;
      } else if (this.contactsIndex == 3) {
        this.paperType = 2;
      }
      this.init();
    },
    //练习模式
    practicesClick (obj) {
      if (this.user == null) {
        this.$message.error("请先登陆");
        return false;
      }
      this.page = 1;
      this.practicesIndex = obj.id;
      this.contactsIndex = 0;
      this.list = [];
      let ob = {
        Id: this.question.Id,
        Name: this.question.Name,
        UserID: this.user.Id,
      };
      // 1套卷练习2随机练习3章节练习4名词刷题5题型练习
      if (obj.id == 1) {
        this.contacts = this.rollUps;
        this.paperType = "";
        this.init();
      } else if (obj.id == 2) {
        this.$router.push({
          path: "/onlineQuestionBank/randomAnswer",
          query: ob,
        });
      } else if (obj.id == 3 || obj.id == 4) {
        this.contacts = this.chapters;
        this.getQuestionBankQueryChapters();
      } else if (obj.id == 4) {
        // this.jump.url = "/onlineQuestionBank/recitationMode";
        // this.jump.ob = ob;
        // // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        // this.dialogVisible = true;
      } else if (obj.id == 5) {
        this.paperType = 2;
        this.init();
      }
    },
    async getQuestionBankQueryChapters () {
      let obj = "";
      if (this.practicesIndex == 3) {
        obj = "?courseId=" + this.question.Id
      } else if (this.practicesIndex == 4) {
        obj = "?courseId=" + this.question.Id + "&isWords=true"
      }
      const res = await questionBankQueryChapters(obj);
      if (res.success === true) {
        this.list = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    async init () {
      this.question = this.$route.query;
      let parm = {
        courseId: this.question.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
        PaperType: this.paperType, //试卷类型，不传时全部，0模拟试卷，1真题
      };
      const res = await questionBankQueryPaperList(parm);
      if (res.success === true) {
        this.list = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.question_details {
  font-size: 14px;
  .cont {
    .title {
      line-height: 60px;
      background-color: #fff;
      padding: 0 30px;
      margin: 10px 0;
      font-size: 16px;
    }
    .practice {
      overflow: hidden;
      background-color: #fff;
      li {
        text-align: center;
        width: 25%;
        float: left;
        padding: 25px 0 20px;
        i {
          font-size: 24px;
        }
        p {
          margin-top: 10px;
        }
      }
      .active {
        color: #1288f4;
      }
    }
    .rollUp {
      .rollUp_top {
        padding: 0 30px;
        margin: 10px 0;
        background-color: #fff;
        span {
          display: inline-block;
          width: 70px;
          text-align: center;
          margin-right: 15px;
          line-height: 60px;
          cursor: pointer;
        }
        .active {
          color: #1288f4;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: #1288f4;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
      .rollUp_cont {
        // padding: 0 40px;
        padding: 20px 0;
        margin: 10px 0;
        background-color: #fff;
        .el-tree {
          padding: 0 30px;
          .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            .tree_right {
              display: flex;
              /deep/.el-progress {
                width: 120px;
                .el-progress-bar {
                  padding-right: 5px !important;
                }
                .el-progress__text {
                  display: none;
                }
              }
              .proportion {
                min-width: 45px;
                text-align: right;
              }
            }
          }
        }
        .el-row {
          line-height: 40px;
          border-bottom: 1px solid #efefef;
          .el-col {
            cursor: pointer;
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>