var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question_details"},[_c('Banner'),_c('div',{staticClass:"cont width_1200"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.question.Name))]),(
        _vm.practicesIndex == 1 ||
        _vm.practicesIndex == 3 ||
        _vm.practicesIndex == 4 ||
        _vm.practicesIndex == 5
      )?_c('div',{staticClass:"rollUp"},[(
          _vm.practicesIndex != 5 && _vm.practicesIndex != 3 && _vm.practicesIndex != 4
        )?_c('div',{staticClass:"rollUp_top"},_vm._l((_vm.contacts),function(rl,i){return _c('span',{key:i,class:_vm.contactsIndex == i ? 'active' : '',on:{"click":function($event){return _vm.contactsClick(rl, i)}}},[_vm._v(_vm._s(rl))])}),0):_vm._e(),(_vm.practicesIndex == 1)?_c('div',{staticClass:"rollUp_cont"},[(_vm.list.length > 0)?_c('div',_vm._l((_vm.list),function(li,i){return _c('el-row',{key:i,attrs:{"type":"flex","justify":"center"},nativeOn:{"click":function($event){return _vm.parcticeClick(li)}}},[_c('el-col',{attrs:{"span":20}},[_vm._v(_vm._s(li.Name))]),_c('el-col',{staticClass:"text_align_right",attrs:{"span":4}},[(li.Category == 2)?_c('p',{staticClass:"c_1288F4"},[_vm._v("免费")]):(li.IsSale != 1 && li.Category == 0)?_c('p',{staticClass:"c_F40707"},[_vm._v(" 课程专属 ")]):(li.IsSale != 1 && li.Category == 1)?_c('p',{staticClass:"c_F40707"},[_vm._v(" VIP专属 ")]):(li.IsSale == 1)?_c('p',{staticClass:"c_F40707"},[_vm._v(" ￥"+_vm._s(_vm._f("priceToFixed")(li.Price))+" ")]):_vm._e()])],1)}),1):_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('no-data')],1)],1)],1)]):_vm._e(),(_vm.practicesIndex == 3 || _vm.practicesIndex == 4)?_c('div',{staticClass:"rollUp_cont"},[_c('el-tree',{attrs:{"indent":25,"default-expand-all":"","highlight-current":"","expand-on-click-node":false,"data":_vm.list,"props":_vm.defaultProps},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var node = ref.node;
        var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(_vm._s(node.label))]),(data.li_attr.questionCount > 0)?_c('span',{staticClass:"tree_right"},[_c('el-progress',{attrs:{"percentage":(data.li_attr.AnswerCount / data.li_attr.questionCount) *
                  100,"color":_vm.customColor}}),_c('div',{staticClass:"proportion"},[_vm._v(" "+_vm._s(data.li_attr.AnswerCount)+"/"+_vm._s(data.li_attr.questionCount)+" ")])],1):_vm._e()])}}],null,false,4121331543)})],1):_vm._e(),(_vm.practicesIndex == 5)?_c('div',{staticClass:"rollUp_cont"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":12}},[_vm._v("题型")]),_c('el-col',{attrs:{"span":8}},[_vm._v("题目数")]),_c('el-col',{staticClass:"text_align_right",attrs:{"span":4}})],1),(_vm.list.length > 0)?_c('div',_vm._l((_vm.list),function(li,i){return _c('el-row',{key:i,attrs:{"type":"flex","justify":"center"},nativeOn:{"click":function($event){return _vm.parcticeClick(li)}}},[_c('el-col',{attrs:{"span":12}},[_vm._v(_vm._s(li.Name))]),_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(li.QuestionCount))]),_c('el-col',{staticClass:"text_align_right",attrs:{"span":4}},[(li.Category == 2)?_c('p',{staticClass:"c_1288F4"},[_vm._v("免费")]):(li.IsSale != 1 && li.Category == 0)?_c('p',{staticClass:"c_F40707"},[_vm._v(" 课程专属 ")]):(li.IsSale != 1 && li.Category == 1)?_c('p',{staticClass:"c_F40707"},[_vm._v(" VIP专属 ")]):(li.IsSale == 1)?_c('p',{staticClass:"c_F40707"},[_vm._v(" ￥"+_vm._s(_vm._f("priceToFixed")(li.Price))+" ")]):_vm._e()])],1)}),1):_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('no-data')],1)],1)],1)],1):_vm._e(),_c('div',[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1)]):_vm._e()]),(_vm.dialogVisible)?_c('camera',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"close":function($event){return _vm.getClose()},"studentface":_vm.postStudentCompareFace}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }